/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
import { SxProps } from '@mui/material';

export const advantageDrawerSX: SxProps = {
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: '375px',
        padding: '24px 24px 24px',
        backgroundColor: 'background.lightgray',
    },
    '& .MuiFormControlLabel-label': {
        fontSize: '12px',
        fontWeight: '400',
        color: 'text.main',
    },
    '& #btn-wrapper': {
        '& .MuiButtonBase-root': {
            '& svg': {
                marginRight: '.25rem',
            },
        },
    },
};

export const updateHeadingSX: SxProps = {
    paddingBottom: '16px',
    borderBottom: '1px solid',
    borderColor: 'border.grayDark',
    marginBottom: '24px',
    '& h3': {
        color: 'heading.dark',
    },
    '> span': {
        position: 'absolute',
        top: '24px',
        right: '24px',
        cursor: 'pointer',
        '& svg': {
            color: 'icons.fillcolor',
        },
    },
};

export const advantageFormSX: SxProps = {
    marginTop: '16px',
    gap: '1.25rem',

    '& > *': {
        flex: { 'xs': 'auto', 'md': '1 !important' },
    },

    '& .MuiInputBase-root': {
        backgroundColor: 'primary.contrastText',
        '& input': {
            padding: '12.5px 14px',
            fontSize: '16px',
            color: 'icons.fillcolor',
            height: 'auto',
        },
        '& fieldset': {
            border: '1px solid',
            borderColor: 'border.grayHover',
            borderRadius: '4px',
        },
    },
    '& .MuiFormHelperText-root': {
        color: '#fff !important',
    },
    '& .MuiButtonBase-root': {
        mt: '3px',
        backgroundColor: 'background.paper',
        color: 'links.blue',
        maxHeight: 'initial',

        '&:hover': {
            backgroundColor: 'background.paper',
        },
        '&:active': {
            backgroundColor: 'background.paper',
        },
        '&.Mui-disabled': {
            backgroundColor: 'border.grayDark',
            color: 'button.disabledText',
        },
    },
};

