/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { primary } from '@/styles/Default/additives';
import { SxProps, Theme } from '@mui/material';

export const carouselProviderSX: SxProps<Theme> = (theme) => ({
    position: 'relative',
    py: 0,
    px: 8,
    // mt: 3,
    // height: '320px',
    [theme.breakpoints.down(410)]: {
        '.carousel__slider.carousel__slider--horizontal': {
            height: 'inherit',
            '> .carousel__slider-tray-wrapper.carousel__slider-tray-wrap--horizontal': {
                height: 'inherit',
                '> .carousel__slider-tray.carousel__slider-tray--horizontal': {
                    height: 'inherit',
                    '> .carousel__slide': {
                        height: 'inherit',
                    },
                },
            },
        },
    },
    '.carousel__play-button': {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'icons.gray',
        opacity: '50%',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px'
    },
    '.carousel__back-button, .carousel__next-button': {
        height: '32px',
        borderRadius: '50%',
        background: 'none',
        border: 'none',
        color: 'icons.fillcolor',
        padding: '0px',
        '& svg': {
            width: '32px',
            height: '32px',
        },
        '&:hover': {
            cursor: 'pointer',
            color: 'icons.fillcolorHover',
        },
    },
    '.carousel__dot-group': {
        display: 'flex',
    },
    '.carousel__dot-group button': {
        height: '16px',
        width: '16px',
        margin: '8px',
        borderRadius: '50%',
        backgroundColor: 'button.gray',
        border: '2px solid ',
        borderColor:'border.gray',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'button.grayHover',
            border: '2px solid',
            borderColor:'border.grayHover'

        },
    },
    'button.carousel__dot--selected': {
        backgroundColor: 'background.secondaryLight',
        border: '2px solid',
        borderColor:'background.secondaryLightHover',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'background.secondaryLight',
            border: '2px solid',
            borderColor:'background.secondaryLightHover',
        },
    },
    div: {
        outline: 'none',
    },
});
