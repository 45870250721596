/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HeroSlider } from '@/components/blocks/HeroCarousel';
import { CustomProgressIndicator } from '@/components/blocks/CustomProgressIndicator';
import { useContentCarousel } from '@/data/Content/ContentCarousel';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import { CarouselOptions } from '@/data/types/Carousel';
import { renderContent } from '@/utils/renderContent';
import { FC, useMemo } from 'react';
import { getCarouselProperties } from '@/utils/getCarouselProperties';
import { Box } from '@mui/material';
import { ProcessedContent } from '@/data/types/Marketing';
import { useContentEvents } from '@/data/Content/_ContentEvents';


const defaultProps: CarouselOptions = {
	naturalSlideWidth: 600,
	naturalSlideHeight: 400,
	visibleSlides: 1,
};
const emptyProperties = {} as WidgetProperties;

export const ContentCarousel: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { data, loading } = useContentCarousel(emsName);
	const carouselProps = useMemo(
		() => getCarouselProperties(properties, defaultProps),
		[properties]
	);
	const { onContentClick } = useContentEvents();
	const slides = useMemo(
		() => (data || []).map((content: ProcessedContent) => renderContent(content, onContentClick(content))).filter(Boolean),
		[data, onContentClick]
	) as JSX.Element[];

	return (
		<>
			{loading ? (
				<CustomProgressIndicator />
			) : (
				<Box sx={{ 'marginTop': '40px', 'marginBottom': '40px', '> .carousel': { paddingLeft: '0', paddingRight: '0', m: { xs: '0 -16px', md: '0' } } }}>
					<HeroSlider slides={slides} carouselProps={carouselProps}></HeroSlider>
				</Box>
			)}
		</>
	);
};
